<template>
  <div class="profile">
    <div class="row align-items-center justify-content-between col-gap-50">
      <div class="col-xxl-5 col-md-6">
        <h1>{{ title }}</h1>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div class="row">
              <div class="col-md-6">
                <base-input
                  :placeholder="$t('First Name')"
                  type="text"
                  rules="required"
                  v-model="profile.firstName"
                  name="First Name"
                />
              </div>
              <div class="col-md-6">
                <base-input
                  :placeholder="$t('Last Name')"
                  type="text"
                  rules="required"
                  v-model="profile.lastName"
                  name="Last Name"
                />
              </div>
            </div>
            <base-date-picker
              @input="getDate"
              v-model="profile.birthdate"
              name="DOB"
              rules="required"
              :placeholder="$t('register.Birth date')"
            />
            <base-select
              :options="gender"
              v-model="selected"
              name="Gender"
              rules="required"
            />
            <!-- Location Changes Start --> <!-- Location Changes Start -->
              <base-locations-country
                place-holder="Select Countryass"
                rules="required"
                v-model="selectedCountry"
                name="Country"
                :locations="locations"
                @input="getStates"
                id="id"
              ></base-locations-country>
              
              <base-locations-state
                rules="required"
                v-model="selectedState"
                name="State"
                :states="states"
                @input="getCities"
                id="id"
              ></base-locations-state>
              
              <base-locations-city
                rules="required"
                v-model="selectedCity"
                name="City"
                :cities="cities"
                id="id"
              ></base-locations-city>
              <!-- Location Changes End   --> <!-- Location Changes End   -->
            <div class="button-row">
              <button
                type="submit"
                class="btn btn-primary large button-submit"
                :class="processing ? 'processing' : ''"
              >
                {{ btnText }}
              </button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div class="col-xxl-7 col-md-6">
        <div class="image">
          <img
            src="@/assets/images/young-woman-with-smartphone.png"
            class="img-fluid"
            alt="young-woman-with-smartphone-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

}
<script>
import { mapState, mapGetters } from "vuex";
import { UPDATE_USER } from "@/graphql/common/mutations";
import { LOCATIONS } from "@/graphql/common/query";
import register from "../../../mixin/register";
import { BIconNutFill } from "bootstrap-vue";
export default {
  mixins: [register],
  data() {
    return {
      title: this.$i18n.t('register.My Profile'),
      btnText: this.$i18n.t('Save'),
      processing: false,
      myGender: [],
      selected: "M",
      gender: [
        { value: "M", text: this.$i18n.t('register.Male') },
        { value: "F", text: this.$i18n.t('register.Female') },
      ],
      profile: {
        firstName: "",
        lastName: "",
        birthdate: "",
        gender: "",
        idLocation: ""
      },
      locations: [],
      countries: [],
      selectedCountry: '',
      states: [],
      selectedState: '',
      cities: [],
      selectedCity: '',
      socialDetails: [],
    };
  },
  apollo: {
    locations: {
      query: LOCATIONS,
      variables() {
        return {
          name: 'all',
        };
      },
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      user: "user"
    }),
  },
  mounted() {
    if (this.user.login_user_id) {
      const facebookProfileData = JSON.parse(this.user.login_user_data);
      this.profile.firstName = facebookProfileData.first_name; 
      this.profile.lastName  = facebookProfileData.last_name;
    }
  },
  methods: {
    onSubmit() {
      const id_location = this.selectedCity === null ? 0 : parseInt(this.selectedCity.id,10);
      this.profile.idLocation = id_location;
      this.updateProfile();
    },
    getDate(date) {
      this.profile.birthdate = date;
    },
    async updateProfile() {
      this.btnText = this.$i18n.t('Saving')+"...";
      this.processing = true;

      await this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: this.profile,
        })
        .then((data) => {
          if (data) {
            if (data.data.updateUser.state == "success") {
              const details = this.$route.params.details;
              this.$router.push(this.$i18nRoute({name: 'userConnectSocial'}));
              this.processing = false;
              this.btnText = this.$i18n.t('Save');
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
          this.btnText = this.$i18n.t('Save');
        });
    },
    getStates() {
      this.states = [];
      this.selectedState = '';
      this.selectedCity = '';
      if (this.selectedCountry !== null) { 
        this.$apollo
        .query({
          query: LOCATIONS,
          variables: {
            search: this.selectedCountry.country,
            name: 'country',
          }
        })
        .then((data) => {
          console.log("States", data.data.locations);
          if (data.data.locations.length > 0) {
            for (var i = 0; i < data.data.locations.length; i++) {
              this.states.push(data.data.locations[i]);
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
      }
    },
    getCities() {
      this.cities = [];
      this.selectedCity = '';
      if (this.selectedState !== null) {
        this.$apollo
        .query({
          query: LOCATIONS,
          variables: {
            search: this.selectedState.state,
            name: 'state',
          }
        })
        .then((data) => {
          console.log("Cities", data.data.locations);
          if (data.data.locations.length > 0) {
            for (var i = 0; i < data.data.locations.length; i++) {
              this.cities.push(data.data.locations[i]);
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });  
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  ::v-deep {
    .v-select-toggle {
      padding-left: 12px;
    }
    .form-control {
      padding-left: 12px;
    }
  }
  .button-submit {
    z-index: 0;
  }
}
::v-deep.invalid-feedback {
  position: static !important;
}
</style>
<style lang="scss">
.LoginRegister {
  @media screen and (max-width: 1400px) {
    min-height: calc(100vh + 60px) !important;
  }
  .contentWrapper {
    padding-top: 0 !important;
  }
  .profile {
    width: 100%;
  }
}
</style>
